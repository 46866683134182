import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


export const References = () => {

    return (

        <Grid container style={{paddingTop:'20px'}}>
            <Grid item xs={5} style={{paddingLeft:'20px'}}>
                <Typography align="justify" variant='h5' paragraph={true}>
                    References
                </Typography>            
                <Typography align="justify" paragraph={true}>
                    Apweiler, R., Bairoch, A., Wu, C.H., Barker, W.C., Boeckmann, B., Ferro, S., Gasteiger, E., Huang, H., Lopez, R., Magrane, M., et al. 
                    (2004). UniProt: the Universal Protein knowledgebase. Nucleic Acids Res 32, D115-119.
                </Typography>
                <Typography align="justify" paragraph={true}>
                    Ashburner, M., Ball, C.A., Blake, J.A., Botstein, D., Butler, H., Cherry, J.M., Davis, A.P., Dolinski, K., Dwight, S.S., Eppig, J.T., et al. 
                    (2000). Gene ontology: tool for the unification of biology. The Gene Ontology Consortium. Nat Genet 25, 25-29.
                </Typography>
                <Typography align="justify" paragraph={true}>
                    Eddy S. R. (2011). Accelerated profile HMM searches. PLOS Comp. Biol., 7:e1002195.
                </Typography>
                <Typography align="justify" paragraph={true}>
                    Hubbard, T., Barker, D., Birney, E., Cameron, G., Chen, Y., Clark, L., Cox, T., Cuff, J., Curwen, V., Down, T., et al. (2002). 
                    The Ensembl genome database project. Nucleic Acids Res 30, 38-41.
                </Typography>  
                <Typography align="justify" paragraph={true}>
                    Leinonen, R., Nardone, F., Oyewole, O., Redaschi, N., and Stoehr, P. (2003). The EMBL sequence version archive. Bioinformatics 19, 1861-1862.
                </Typography>                   
                <Typography align="justify" paragraph={true}>
                    Lhomond, S., Avril, T., Dejeans, N., McMahon, M., Pineau, R., Papadodima, O., Voutetakis, K., Logotheti, M., Pallares-Lupon, 
                    N., Schmit, K., et al. (2018). Dual IRE1 RNase functions dictate glioblastoma tumor development. Embo Mol Med In press.
                </Typography>
                <Typography align="justify" paragraph={true}>
                    Li, W., Jaroszewski, L., Godzik, A. (2001). Clustering of highly homologous sequences to reduce the size of large protein database. 
                    Bioinformatics 17, 282-283. 
                </Typography>                
                <Typography align="justify" paragraph={true}>
                    Lombard, V., Camon, E.B., Parkinson, H.E., Hingamp, P., Stoesser, G., and Redaschi, N. (2002). EMBL-Align: 
                    a new public nucleotide and amino acid multiple sequence alignment database. Bioinformatics 18, 763-764.
                </Typography>
                <Typography align="justify" paragraph={true}>
                    Resnik, P. (1995). Using Information Content to Evaluate Semantic Similarity in a Taxonomy. IJCAI. 95. 
                </Typography>              
                <Typography align="justify" paragraph={true}>
                    Wang J. Z., Du Z., Payattakool R., Yu P. S., Chen C. (2007). A new method to measure the semantic similarity of GO terms. 
                    Bioinformatics 23, 1274–1281.
                </Typography>              
            </Grid>     
        </Grid>
    )

}

