import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Aux from './Aux.js';
import Typography from '@material-ui/core/Typography';
import classes from './Modal.module.css';




class ImageModal extends React.Component {

    state = {
      open: false
    }

    handleOpen = () => {
      this.setState({ open: true });
    };
  
    handleClose = () => {
      this.setState({ open: false });
    };

    render() {


      return (

        <Aux>
        <Card className={classes.Card} onClick={this.handleOpen}>
            <CardActionArea>
                <CardMedia className={classes.Media} component="img" image={this.props.image_file}/>
                  <CardContent>
                      <Typography component="p">{this.props.image_caption}</Typography>
                  </CardContent>
            </CardActionArea>
        </Card>

        <Modal style={{background: 'rgba(240, 248, 255, 0.8)'}} aria-labelledby="simple-modal-title" 
               aria-describedby="simple-modal-description" open={this.state.open} onClose={this.handleClose}>
            <div className={classes.Paper}>
                <img src={this.props.image_file} style={{maxWidth:'800px'}}/>
                <Typography variant='h5'>{this.props.image_caption}</Typography>
            </div>
        </Modal>

        </Aux>

      );


    }

  }


  
export default ImageModal;