import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Aux from './Aux.js';
import DataCollection from './DataCollection.js';
import {Methods1, Methods2, Methods3} from './Methods.js';
import {References} from './References.js';
import {Abstract} from './Abstract.js';
import Divider from '@material-ui/core/Divider';


class Container extends Component {

    state = {
        value: 0
    };


    handleChange = (event, value) => {
        this.setState({ value });
    };


    render() {


        const { classes } = this.props;
        const { value } = this.state;

        return (
            <Aux>
            <AppBar position="static" color="default" style={{paddingTop:'10px', paddingLeft:'10px'}}>

                <Typography variant="h4" color="inherit">
                    Proteostasis imprinting across evolution
                </Typography>
                <Divider />
                <Typography variant="subtitle1" paragraph={true} color="inherit">
                <br/>
                Theodoros Koutsandreas (1,2), Aristotelis Chatziioannou (1,2), Eric Chevet (3,4) and Brice Felden (5)  <br/>
                </Typography> 
                <Typography variant="subtitle2" paragraph={true} color="inherit">
                1. Institute of Biology, Medicinal Chemistry; Biotechnology, NHRF, Athens, Greece. <br/>
                2. e-NIOS PC, Kallithea-Athens, Greece. <br/>
                3 INSERM U1242, Univ Rennes, Rennes, France. <br/>
                4 Centre de Lutte Contre le Cancer Eugène Marquis, Rennes, France. <br/>
                5 INSERM U1230, Univ Rennes, Rennes, France.
                </Typography>           


            <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
            >
                <Tab label="Abstract" />
                <Tab label="Data Collection" />
                <Tab label="Proteostasis-based phylogenetic trees" />
                <Tab label="Ribosomal & HSP-based phylogenetic trees" /> 
                <Tab label="Comparison of phylogenetic trees" /> 
                <Tab label="References" />                                   
            </Tabs>          
            </AppBar>

            <Typography component='div'>
                {value===0 && <h1><Abstract /></h1>}
                {value===1 && <DataCollection />}
                {value===2 && <Methods1 />}
                {value===3 && <Methods2 />} 
                {value===4 && <Methods3 />}  
                {value===5 && <References />}                                  
            </Typography>
            </Aux>
        )
        
    }

}

export default Container;