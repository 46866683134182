import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ImageModal from './ImageModal';
import {DataCollectionText} from './Texts.js';
import eukaryotes_gene_lists from './assets/plots/eukaryotes_gene_lists.png';
import prokaryotes_gene_lists from './assets/plots/prokaryotes_gene_lists.png';
import rRNA_seqs_preprocessing from './assets/plots/rRNA_seqs_preprocessing.png';
import hsp_seqs_preprocessing from './assets/plots/hsp_seqs_preprocessing.png';

import organisms from './assets/data/organisms.csv';
import gene_list from './assets/data/gene_lists.zip';
import rib_seqs from './assets/data/ribosomal_seqs.csv';
import hsp70_initial_seqs from './assets/data/HSP70_initial_sequences.zip';
import hsp70_final_seqs from './assets/data/HSP70_final_sequences.fa';
import hsp40_initial_seqs from './assets/data/HSP40_initial_sequences.zip';
import hsp40_final_seqs from './assets/data/HSP40_final_sequences.fa';


const fig1 = "Figure 1: Workflow for the construction of Eukaryotes gene lists.";
const fig2 = "Figure 2: Workflow for the construction of Prokaryotes gene lists.";
const fig3 = "Figure 3: Workflow to retrieve a representative ribosomal sequence for each organism.";
const fig4 = "Figure 4: Workflow to calculate a consensus amino acid sequence for each heat shock protein and each organism.";


const DataCollection = () => {

    return (
        <Grid container style={{paddingTop:'20px'}}>
            <Grid item xs={6} style={{paddingLeft:'20px'}}>
                <DataCollectionText />
            </Grid>
            <Grid item xs={4} style={{paddingLeft:'20px'}}>
                <Grid container xs={12}>
                    <Grid item xs={6}>
                        <ImageModal image_file={eukaryotes_gene_lists} image_caption={fig1}/>
                    </Grid>
                    <Grid item xs={6}>
                        <ImageModal image_file={prokaryotes_gene_lists} image_caption={fig2}/>
                    </Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={6}>
                        <ImageModal image_file={rRNA_seqs_preprocessing} image_caption={fig3}/>
                    </Grid>
                    <Grid item xs={6}>
                        <ImageModal image_file={hsp_seqs_preprocessing} image_caption={fig4}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={2} style={{paddingLeft:'20px'}}>
                <Grid container xs={12}>
                    <Grid item xs={12} style={{marginBottom:'20px'}}>
                        <a download="organisms.csv" href={organisms} style={{textDecoration:'none'}}>
                            <Card>
                                <CardActionArea>
                                    <CardContent style={{textAlign:'center'}}>
                                        <Typography variant="h5">Selected Organisms (csv)</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </a>
                    </Grid>               
                    <Grid item xs={12} style={{marginBottom:'20px'}}>
                        <a download="gene_lists.zip" href={gene_list} style={{textDecoration:'none'}}>
                            <Card>
                                <CardActionArea>
                                    <CardContent style={{textAlign:'center'}}>
                                        <Typography variant="h5">Gene Lists (zip)</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </a>
                    </Grid>
                    <Grid item xs={12} style={{marginBottom:'20px'}}>
                        <a download="ribosomal_sequences.csv" href={rib_seqs} style={{textDecoration:'none'}}>
                            <Card>
                                <CardActionArea>
                                    <CardContent style={{textAlign:'center'}}>
                                        <Typography variant="h5">Ribosomal Sequences (csv)</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </a>
                    </Grid>
                    <Grid item xs={12} style={{marginBottom:'20px'}}>
                        <a download="hsp70_initial_sets.zip" href={hsp70_initial_seqs} style={{textDecoration:'none'}}>
                            <Card>
                                <CardActionArea>
                                    <CardContent style={{textAlign:'center'}}>
                                        <Typography variant="h5">HSP70 Initial Sequences (zip)</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </a>
                    </Grid>
                    <Grid item xs={12} style={{marginBottom:'20px'}}>
                        <a download="hsp70_final_sequences.fa" href={hsp70_final_seqs} style={{textDecoration:'none'}}>
                            <Card>
                                <CardActionArea>
                                    <CardContent style={{textAlign:'center'}}>
                                        <Typography variant="h5">HSP70 Final Sequences (fasta)</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </a>
                    </Grid>
                    <Grid item xs={12} style={{marginBottom:'20px'}}>
                        <a download="hsp40_initial_sets.zip" href={hsp40_initial_seqs} style={{textDecoration:'none'}}>
                            <Card>
                                <CardActionArea>
                                    <CardContent style={{textAlign:'center'}}>
                                        <Typography variant="h5">HSP40 Initial Sequences (zip)</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </a>
                    </Grid>
                    <Grid item xs={12} style={{marginBottom:'20px'}}>
                        <a download="hsp40_final_sequences.fa" href={hsp40_final_seqs} style={{textDecoration:'none'}}>
                            <Card>
                                <CardActionArea>
                                    <CardContent style={{textAlign:'center'}}>
                                        <Typography variant="h5">HSP40 Final Sequences (fasta)</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </a>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

}


export default DataCollection;