import React from 'react';
import './App.css';
import Container from './Container.js';


function App() {
  return (
    <Container />
  );
}

export default App;
