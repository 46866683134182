import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {MethodsText1, MethodsText2, MethodsText3} from './Texts.js';
import ImageModal from './ImageModal';

import distances_GO_P from './assets/plots/distances_GO_P.png';
import distances_GO_C from './assets/plots/distances_GO_C.png';
import GO_P_clusters from './assets/plots/GO_P_clusters_plot.png';
import GO_C_clusters from './assets/plots/GO_C_clusters_plot.png';

import GO_P_enriched_terms from './assets/data/GO_P_enriched_terms.zip';
import GO_P_enriched_unique_terms from './assets/data/GO_P_enriched_and_unique_terms.zip';
import GO_C_enriched_terms from './assets/data/GO_C_enriched_terms.zip';
import GO_C_enriched_unique_terms from './assets/data/GO_C_enriched_and_unique_terms.zip';
import GO_P_distances_matrix from './assets/data/distances_GO_P.csv';
import GO_C_distances_matrix from './assets/data/distances_GO_C.csv';

import HSP40_heatmap from './assets/plots/HSP40_heatmap.png';
import HSP70_heatmap from './assets/plots/HSP70_heatmap.png';
import rRNA_heatmap from './assets/plots/rRNAs_heatmap.png';

import HSP40_distances_matrix from './assets/data/HSP40_distance_matrix.csv';
import HSP70_distances_matrix from './assets/data/HSP70_distance_matrix.csv';
import rRNA_distances_matrix from './assets/data/ribosomal_seqs_distance_matrix.csv';

import centroid_distances from './assets/plots/centroid_distances.png';

const fig5 = 'Figure 5: Phylogenetic tree based on functional similarities. Enrichment analysis of gene lists revealed a set of GO BP terms for each organism. '+ 
             'These lists were computationally compared, exploiting the hierarchical structure of GO graph.';
const fig6 = 'Figure 6: Phylogenetic tree based on compartmental similarities. Enrichment analysis of gene lists revealed a set of GO CC terms for each organism. '+ 
            'These lists were computationally compared, exploiting the hierarchical structure of GO graph.';
const fig7 = "Figure 7: GO BP terms were clustered to 40 clusters, revealing general differences and similarities among the organisms.";
const fig8 = "Figure 8: GO BP terms were clustered to 25 clusters, revealing general differences and similarities among the organisms.";

const fig9 = "Figure 9: Phylogenetic tree based on similarities among the ribosomal sequences.";
const fig10 = "Figure 10: Phylogenetic tree based on similarities among the HSP70s sequences.";
const fig11 = "Figure 11: Phylogenetic tree based on similarities among the HSP40s sequences.";

const fig12 = 'Figure 12: Two dimensional projection of organism distances, based on the four different criteria. The central point'+
              'depicts the centroid of evolutionary tree';

export const Methods1 = () => {

    return (

        <Grid container style={{paddingTop:'20px'}}>
            <Grid item xs={6} style={{paddingLeft:'20px'}}>
                <MethodsText1 />
            </Grid>
            <Grid item xs={4} style={{paddingLeft:'20px'}}>
                <Grid container xs={12}>
                    <Grid item xs={6}>
                        <ImageModal image_file={distances_GO_P} image_caption={fig5}/>
                    </Grid>
                    <Grid item xs={6}>
                        <ImageModal image_file={distances_GO_C} image_caption={fig6}/>
                    </Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={6}>
                        <ImageModal image_file={GO_P_clusters} image_caption={fig7}/>
                    </Grid>
                    <Grid item xs={6}>
                        <ImageModal image_file={GO_C_clusters} image_caption={fig8}/>
                    </Grid>
                </Grid>  
            </Grid>
            <Grid item xs={2} style={{paddingLeft:'20px'}}>
                <Grid container xs={12}>
                    <Grid item xs={12} style={{marginBottom:'20px'}}>
                        <a download="GO_BP_enriched_terms.zip" href={GO_P_enriched_terms} style={{textDecoration:'none'}}>
                            <Card>
                                <CardActionArea>
                                    <CardContent style={{textAlign:'center'}}>
                                        <Typography variant="h5">GO BP Enriched Terms (zip)</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </a>
                    </Grid>
                    <Grid item xs={12} style={{marginBottom:'20px'}}>
                        <a download="GO_BP_enriched_unique_terms.zip" href={GO_P_enriched_unique_terms} style={{textDecoration:'none'}}>
                            <Card>
                                <CardActionArea>
                                    <CardContent style={{textAlign:'center'}}>
                                        <Typography variant="h5">GO BP Unique Terms (zip)</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </a>
                    </Grid>
                    <Grid item xs={12} style={{marginBottom:'20px'}}>
                        <a download="GO_BP_distance_matrix.csv" href={GO_P_distances_matrix} style={{textDecoration:'none'}}>
                            <Card>
                                <CardActionArea>
                                    <CardContent style={{textAlign:'center'}}>
                                        <Typography variant="h5">GO BP Distance Matrix (csv)</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </a>
                    </Grid>  
                    <Grid item xs={12} style={{marginBottom:'20px'}}>
                        <a download="GO_CC_enriched_terms.zip" href={GO_C_enriched_terms} style={{textDecoration:'none'}}>
                            <Card>
                                <CardActionArea>
                                    <CardContent style={{textAlign:'center'}}>
                                        <Typography variant="h5">GO CC Enriched Terms (zip)</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </a>
                    </Grid>
                    <Grid item xs={12} style={{marginBottom:'20px'}}>
                        <a download="GO_CC_enriched_unique_terms.zip" href={GO_C_enriched_unique_terms} style={{textDecoration:'none'}}>
                            <Card>
                                <CardActionArea>
                                    <CardContent style={{textAlign:'center'}}>
                                        <Typography variant="h5">GO CC Unique Terms (zip)</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </a>
                    </Grid> 
                    <Grid item xs={12} style={{marginBottom:'20px'}}>
                        <a download="GO_CC_distance_matrix.csv" href={GO_C_distances_matrix} style={{textDecoration:'none'}}>
                            <Card>
                                <CardActionArea>
                                    <CardContent style={{textAlign:'center'}}>
                                        <Typography variant="h5">GO CC Distance Matrix (csv)</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </a>
                    </Grid>                                      
                </Grid>


            </Grid>           

        </Grid>
    )

}



export const Methods2 = () => {

    return (

        <Grid container style={{paddingTop:'20px'}}>
            <Grid item xs={3} style={{paddingLeft:'20px'}}>
                <MethodsText2 />
            </Grid>
            <Grid item xs={9} style={{paddingLeft:'20px'}}>
                <Grid container xs={12}>
                    <Grid item xs={3}>
                        <ImageModal image_file={rRNA_heatmap} image_caption={fig9}/>
                        <a download="rRNA_distances_matrix.csv" href={rRNA_distances_matrix} style={{textDecoration:'none'}}>
                            <Card style={{maxWidth:'90%'}}>
                                <CardActionArea>
                                    <CardContent style={{textAlign:'center'}}>
                                        <Typography variant="h5">rRNAs Distance Matrix (csv)</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </a>                       
                    </Grid>
                    <Grid item xs={3}>
                        <ImageModal image_file={HSP70_heatmap} image_caption={fig10}/>
                        <a download="HSP70_distances_matrix.csv" href={HSP70_distances_matrix} style={{textDecoration:'none'}}>
                            <Card style={{maxWidth:'90%'}}>
                                <CardActionArea>
                                    <CardContent style={{textAlign:'center'}}>
                                        <Typography variant="h5">HSP70s Distance Matrix (csv)</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </a>
                    </Grid>
                    <Grid item xs={3}>
                        <ImageModal image_file={HSP40_heatmap} image_caption={fig11}/>
                        <a download="HSP40_distances_matrix.csv" href={HSP40_distances_matrix} style={{textDecoration:'none'}}>
                            <Card style={{maxWidth:'90%'}}>
                                <CardActionArea>
                                    <CardContent style={{textAlign:'center'}}>
                                        <Typography variant="h5">HSP40s Distance Matrix (csv)</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </a> 
                    </Grid>                    
                </Grid>              
            </Grid>            
        </Grid>
    )

}



export const Methods3 = () => {

    return (

        <Grid container style={{paddingTop:'20px'}}>
            <Grid item xs={3} style={{paddingLeft:'20px'}}>
                <MethodsText3 />
            </Grid>
            <Grid item xs={9} style={{paddingLeft:'20px'}}>
                <Grid item xs={6}>
                    <ImageModal image_file={centroid_distances} image_caption={fig12}/>
                </Grid>                                
            </Grid>            
        </Grid>
    )

}

