import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


export const Abstract = () => {

    return (

        <Grid container style={{paddingTop:'20px'}}>
            <Grid item xs={6} style={{paddingLeft:'20px'}}>
                <Typography align="justify" variant='h3' paragraph={true}>
                    Abstract
                </Typography>            
                <Typography align="justify" paragraph={true}>
                The control of protein homeostasis (a.k.a. proteostasis) is by essence associated with the primary functions of life, 
                and therefore with evolution. However, it is unclear how the cellular proteostasis machines have evolved to adjust 
                the protein biogenesis needs, to the constraints from the environment. Herein, we show that the molecular components ensuring 
                proteostasis are a reliable metric to deconvolute the life forms into archaea, bacteria and eukaryotes, and to assess the evolution 
                rates among species. Proteostasis components specific of a species-genus-family-order-class-phylum are detectable, suggesting 
                that its complexity correlates to the grade of evolution the species have reached. Individual components, however, such as 
                the Heat Shock Proteins (HSP) do not accurately mark evolution. We analyzed gene conservation, gain or loss that occurred throughout 
                proteostasis evolution and which revealed a dichotomy within proteostasis evolution, with highly conserved parts (e.g. chaperones) 
                but also many species-specific entities. Since proteostasis is implicated into cell fitness, aging control and the onset of several 
                diseases, it could be considered as a new metric to tackle mechanisms underlying select ‘gain-of-functions’ and their biological impacts. 
                </Typography>
            </Grid>     
        </Grid>
    )

}

